// Header.js

import React from 'react';

const Header = () => {
    return (
        <div>
            <h1>Willkommen beim Noten Finder</h1>
            <h3>Hier kannst du deine Punkte eingeben</h3>

        </div>
    );
};

export default Header;
