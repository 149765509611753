import React, { useState } from 'react';
import './App.css'; // Importiere die CSS-Datei
import PunkteInNote from './component/PunkteInNote';
import Header from './component/Header';
import Main from './component/Main';


const NotenFinder = () => {
    const [punkte, setPunkte] = useState('');
    const [erzielteNote, setErzielteNote] = useState('');


    const notenArray = [
        { punkte: 15, note: '1+' },
        { punkte: 14, note: '1' },
        { punkte: 13, note: '1-' },
        { punkte: 12, note: '2+' },
        { punkte: 11, note: '2' },
        { punkte: 10, note: '2-' },
        { punkte: 9, note: '3+' },
        { punkte: 8, note: '3' },
        { punkte: 7, note: '3-' },
        { punkte: 6, note: '4+' },
        { punkte: 5, note: '4' },
        { punkte: 4, note: '4-' },
        { punkte: 3, note: '5+' },
        { punkte: 2, note: '5' },
        { punkte: 1, note: '5-' },
        { punkte: 0, note: '6' },

    ];
    const handlePunkteChange = (event) => {
        const neuePunkte = parseInt(event.target.value, 10);

        // Überprüfen, ob die eingegebenen Punkte zwischen 0 und 15 liegen
        if (!isNaN(neuePunkte) && neuePunkte >= 0 && neuePunkte <= 15) {
            setPunkte(neuePunkte.toString()); // Konvertiere die Punkte in einen String

            // Note finden
            let gefundeneNote = '';
            for (const zuordnung of notenArray) {
                if (neuePunkte >= zuordnung.punkte) {
                    gefundeneNote = zuordnung.note;
                    break;
                }
            }

            setErzielteNote(gefundeneNote);
        } else {
            // Falls die eingegebenen Punkte außerhalb des gültigen Bereichs liegen, setze auf den letzten gültigen Wert
            setPunkte(punkte);
            setErzielteNote('');
        }
    };

    const textFarbe = punkte < 5 ? 'red' : 'inherit'; // Wenn Punkte kleiner als 3, dann Rot, sonst Standardfarbe

    return (
        <div>
            <Header />
            <label className='label'>
                <input type="range" min="0" max="15" step="1" value={punkte} onChange={handlePunkteChange} defaultValue="8" />
                {/* Anzeige der ausgewählten Punkte */}<br />
                <span>{punkte || '8'} Punkte</span>
            </label>

            {erzielteNote && (
                <p style={{ color: textFarbe }}>
                    Bei {punkte} Punkten erhältst du die Note: {erzielteNote}
                </p>

            )}
            <Main />
            <PunkteInNote />
        </div>
    );





};

export default NotenFinder; 