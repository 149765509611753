// MAi.js

import React from 'react';

const Main = () => {
    return (
        <div>

            <h3>und die entsprechende Note sowie den Punktedurchschnitt als Dezimalzahl berechnen.</h3>
            <h4>bitte . statt , benutzen</h4>
        </div>
    );
};

export default Main;
